<template>
  <div id="app">
    <!-- <div v-if="menuKey == 1" class="floatWindow">
      红狮集团（金银业贸易场AA类行员）欢迎您！您现在访问的是香港网站。投资需谨慎。
      <img @click="closer" src="../static/index/closer.png" alt="">
    </div> -->
    <layout-vue v-if="this.control == false" @getKey="getKey"></layout-vue>
    <router-view @controlShow="controlShow" />
    <contactusVue v-if="this.control == false"></contactusVue>
  </div>
</template>

<script>
import eventBusMitter from '../utils/eventbus'
import LayoutVue from './components/Layout.vue'
import ContactusVue from './components/Contactus.vue'
import { getLive800 } from './components/subject/classRoom/api/login'
import { mapActions } from 'vuex';

export default {
  name: 'App',
  components: {
    LayoutVue,
    ContactusVue,
  },
  data() {
    return {
      menuKey: null,
      control: false,
      live800Url: '',
      live800UrlUc: '',
    }
  },
  created() {
    // 接收eventBus传来的值
    eventBusMitter.on('eventBus', (res) => {
      this.control = res.key
    })
    getLive800({
      key: 'PmH5LiveUrl',
    }).then((res) => {
      this.live800Url = res.data
      sessionStorage.setItem('liveH5', res.data)
    })
    getLive800({
      key: 'PmUcLiveUrl',
    }).then((res) => {
      this.live800UrlUc = res.data
      sessionStorage.setItem('liveUC', res.data)
    })
    const Rhref = window.location.host;
    if(Rhref.indexOf('rlchltd')>-1){
      this.getAndroidDownloadUrl(9946);
    } else {
      this.getAndroidDownloadUrl();
    }
    this.getIosDownloadUrl('IOSDownLoadLinkMT5');
    this.getIosDownloadUrl();
    this.getAICustomService();
  },
  mounted() {
    console.log('render-event, 1111')
    document.dispatchEvent(new Event('render-event'));
  },
  methods: {
    ...mapActions('common', {
      getAndroidDownloadUrl: 'getAndroidDownloadUrl',
      getIosDownloadUrl: 'getIosDownloadUrl',
      getAICustomService: 'getAICustomService',
    }),
    getKey(key) {
      this.menuKey = key
    },
    controlShow(key) {
      this.control = key
    },
    closer() {
      this.menuKey = !this.menuKey
    },
  },
}
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  .floatWindow {
    width: 100%;
    min-width: 1130px;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    padding: 8px 0px;
    color: #ff9b1c;
    background-color: rgba(240, 215, 132, 0.4);
    img {
      cursor: pointer;
    }
  }
}
ul {
  padding-left: 0;
  margin: 0 auto;
}
ul li {
  padding-left: 0px;
  list-style: none;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
.path {
  stroke: #e90013 !important;
}

@media screen and (max-width: 500px) {
  body {
    overflow-x: hidden;
  }
}
</style>
