import request from '../utils/request'
const env = process.env.NODE_ENV;
const textApi = 'https://testwww.rlc525.com/api/proxy';
//const testApi = '/testapi'
const originApi = 'https://www.rlcproltd.net/api/proxy';
import config from '../config'
// 登录接口
export function handleLogin(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}
// 验证码接口
export function handleSms(data) {
  return request({
    url: '/Common/verifyCode',
    method: 'post',
    data
  })
}
// 注册接口
export function handleRegister(data) {
  return request({
    url: '/Common/register',
    method: 'post',
    data
  })
}
// 修改密码接口
export function handlePassword(data) {
  return request({
    url: '/Common/modPassword',
    method: 'post',
    data
  })
}
// 新注册接口
export function newhandleRegister(data) {
  data.IsMt5 = true;
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/U101',
    // url: textApi + '/v4/U101',
    url: originApi + '/v4/U101',
    method: 'post',
    data,
    headers: {
      platform: 1
    }
  })
}
// 新验证码接口
export function newhandleSms(data) {
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/U001',
    // url: textApi + '/v4/U001',
    url: originApi + '/v4/U001',
    method: 'post',
    data
  })
}
// 获取live800
export function getLive800(params) {
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/ACV100',
    // url: textApi + '/v4/ACV100',
    url: originApi + '/v4/ACV100',
    method: 'get',
    params
  })
}
// 查询用户基础信息
export function getAccountInfo(params) {
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/UI101',
    //url: textApi + '/v4/UI101',
    url: '/api/getAccountInfo',
    method: 'get',
    params,
  })
}

// PM修改用户昵称
export function PMSetUserNick(data) {
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/UN100',
    // url: textApi + '/v4/UN100',
    url: originApi + '/v4/UN100',
    method: 'post',
    data
  })
}
// PM修改用户头像
export function PMSetUserHead(data) {
  return request({
    // url: config.apiUrl[env].baseUrl + '/v4/UHS100',
    // url: textApi + '/v4/UHS100',
    url: originApi + '/v4/UHS100',
    method: 'post',
    data
  })
}