import request from '../utils/axios';
// 宝汇
const base = 'https://tocms.rwkad.com';
// 鑫盛
// const xsApi = 'https://www.rlcvipltd.net/api/proxy';
const xsApi ='/api/proxy'

var originApi = window.location.origin + '/api/proxy';
if (location.origin.indexOf("localhost") > -1) {
  originApi = 'https://testwww.rlc525.com/api/proxy'
}
// const originApi = 'https://www.rlcvipltd.net/api/proxy';
// index--头条
export function getHeadlineNewsApi(data) {
  return request({
    url: base + '/api/services/app/HeadlineNews/ViewList',
    method: 'POST',
    data,
    headers: {
      // 'Accept-Language': 'en',
      // 'Cms.Content.Culture': 'en'
    }
  })
}
// index--财经日历
export function getViewListWithHolidayApi(data) {
  return request({
    url: xsApi + '/v3/A603',
    method: 'POST',
    data
  })
}
// index--数据中心
export function datacenterInit(data) {
  return request({
    url: xsApi + '/Crawl/DataCenter_RealTimeTrade?size=7&days=7',
    method: 'POST',
    data
  })
}
// Notice--平台公告
export function noticeInit(data) {
  return request({
    url: xsApi + '/v3/A630',
    method: 'POST',
    data
  })
}
// market-- 新闻资讯
export function newList(data) {
  return request({
    url: xsApi + '/v4/I106',
    method: 'POST',
    data
  })
}
// market--专家评论
export function expertList(data) {
  return request({
    url: xsApi + '/v4/I107',
    method: 'POST',
    data
  })
}
// details--持仓比例
export function datacenterGoldSilv(data) {
  return request({
    url: xsApi + '/Crawl/DataCenter_GoldSilverPos_24H',
    method: 'POST',
    data
  })
}
// index -- 全部开仓
export function DataCenter_GoldSilverPos_Total(data) {
  return request({
    url: xsApi + '/Crawl/DataCenter_GoldSilverPos_Total',
    method: 'POST',
    data
  })
}
// details--未平仓品种比率
export function datacenterGoldSilverScale(data) {
  return request({
    url: xsApi + '/Crawl/DataCenter_GoldSilverScale',
    method: 'POST',
    data
  })
}
// market--市场快讯
export function fastList(data) {
  return request({
    url: xsApi + '/v3/A620',
    method: 'POST',
    data
  })
}
// product--今日汇率
export function TodayList(data) {
  return request({
    url: xsApi + '/Config/GetExchangeRate',
    method: 'POST',
    data
  })
}
// product--近七天汇率
export function sevenDayList(data) {
  return request({
    url: xsApi + '/Config/GetHistoryExchangeRateList',
    method: 'POST',
    data
  })
}
// notice--平台公告详情页
export function noticeDetails(data) {
  return request({
    url: xsApi + '/v3/A631',
    method: 'POST',
    data
  })
}
// news--新闻详情页
export function newsDetails(data) {
  return request({
    url: xsApi + '/v4/I109',
    method: 'POST',
    data
  })
}
// expert--专家评论详情页
export function expertDetails(data) {
  return request({
    url: xsApi + '/v4/I110',
    method: 'POST',
    data
  })
}
// // index--首页轮播图配置
// export function swiperBanner(data) {
//   return request({
//     url: xsApi + '/common/brandLineAndImageList',
//     method: 'POST',
//     data
//   })
// }
export function swiperBanner(data) {
  return request({
    url: xsApi + '/v4/PA159',
    method: 'POST',
    data
  })
}
// activity(世界杯)--投票活动
export function activityVote(data) {
  return request({
    url: originApi + '/v4/A133',
    method: 'POST',
    data
  })
}
// activity(世界杯)--投票详情
export function VoteDetails(data) {
  return request({
    url: originApi + '/v4/A131',
    method: 'POST',
    data
  })
}
//activity(世界杯)--图片验证码
export function verifyImgCode(data) {
  return request({
    url: originApi + '/v4/U001',
    method: 'POST',
    data
  })
}
//activity(世界杯)--快捷注册
export function quickRegister(data) {
  data.IsMt5 = true;
  return request({
    url: originApi + '/v4/U101',
    method: 'POST',
    data,
    headers: {
      platform: 1
    }
  })
}
//activity(世界杯)--抽奖
export function luckDraw(data) {
  return request({
    url: originApi + '/v4/A142',
    method: 'POST',
    data
  })
}
//activity(世界杯)--查询转盘
export function searchId(data) {
  return request({
    url: originApi + '/v4/A143?id=' + data,
    method: 'GET',
  })
}
//activity(世界杯)--查询抽奖次数
export function searchUserNum(data) {
  return request({
    url: originApi + '/v4/A144',
    method: 'POST',
    data
  })
}
//activity(世界杯)--查询用户历史抽奖结果
export function searchUserHistory(data) {
  return request({
    url: originApi + '/v4/A145',
    method: 'POST',
    data
  })
}
//直播间banner图配置
export function zhiboBanner(data) {
  return request({
    url: originApi + '/v4/PA159',
    method: 'POST',
    data
  })
}

//首页调取正在直播的房间
export function livingRoomNow(params) {
  return request({
    // url: 'http://manage.huiadmin.lan/apis/Common/brooms/living?platid=1',
    url: 'https://www.rlcvipltd.net/apis/Common/brooms/living?platid=1',
    method: 'GET',
    params
  })
}
//获取非农活动时间
export function getFeinongTime(params) {
  return request({
    url: originApi + '/v4/FNA100',
    method: 'GET',
    params
  })
}
// 查询答题活动期数
export function getIssue(data) {
  return request({
    url: originApi + '/v4/A172',
    method: 'POST',
    data
  })
}
// 查询答题活动题目
export function getQuestion(data) {
  return request({
    url: originApi + '/v4/A173',
    method: 'POST',
    data
  })
}
// 提交答题答案
export function submitQuestion(data) {
  return request({
    url: originApi + '/v4/A174',
    method: 'POST',
    data
  })
}
// 查询用户答案列表
export function getAnswer(data) {
  return request({
    url: originApi + '/v4/A175',
    method: 'POST',
    data
  })
}
// 查询答题活动账户奖励
export function getReward(data) {
  return request({
    url: originApi + '/v4/A177',
    method: 'POST',
    data
  })
}
//CRM埋点 ------------------------------begian
export function getHashParams(type) {
  var result = {};
  var params = (window.location.hash.split('?')[1] || '').split('&');
  var paramParts = [];
  for (var param in params) {
    if (params.hasOwnProperty(param)) {
      paramParts = params[param].split('=');
      result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
    }
  }
  if (type) {
    return result[type];
  }
  return result;
}

// 获取渠道
export function appCode() {
  const referrer = window.location.href.split('?')[1]
  const obj = {}
  if (referrer) {
    const referrArr = referrer.split('&');
    for (let i = 0; i < referrArr.length; i++) {
      obj[referrArr[i].split('=')[0]] = referrArr[i].split('=')[1]
    }
  }
  const nowParams = location.search.replace(/^\?/, '')
  const objParams = nowParams.split('&');
  const obj2 = {}
  for (let i = 0; i < objParams.length; i++) {
    obj2[objParams[i].split('=')[0]] = objParams[i].split('=')[1]
  }
  return Object.assign({}, obj, obj2)
}
export function maidianOpenreal(data) {
  const paramsMd = appCode()

  if (getHashParams('clientid')) {
    localStorage.setItem('clientid', getHashParams('clientid'))
    var clientid = localStorage.getItem('clientid')
  } else if (localStorage.getItem('clientid')) {
    clientid = localStorage.getItem('clientid')
  } else {
    clientid = (Math.random() * 10000000).toString(16).substr(0, 4) + '_' + (new Date()).getTime() + '_' + Math.random().toString().substr(2, 5);
    localStorage.setItem('clientid', clientid)
  }
  return request({
    url: 'https://crm.njanea.com:12343/maidian/scada/collect',
    data: [
      {
        sourceid: 1,
        usertype: 1,
        clientid: clientid,
        eventtime: new Date().getTime(),
        detail: {
          channelname: '手动输入',
          backurl: data.backurl || document.referrer,
          currenturl: data.currenturl || window.location.href,
          account: data.account || '',
          accounttype: data.accounttype || '',
          medium: paramsMd['utm_medium'] || undefined, //媒介
          keyworld: paramsMd['utm_term'] || undefined, //关键字
          plan: paramsMd['utm_campaign'] || undefined, //计划
          unit: paramsMd['utm_content'] || undefined, //单元
          packageid: appCode()['v'] || undefined,
        },
        eventname: data.eventname,
        eventdescribe: data.eventdescribe,
        eventtype: data.eventType || 'other',
      }
    ],
    method: 'post',
  })
}
//CRM埋点 ------------------------------end
// 通过u109判断个人信息认证到哪一步
export function getAccountCompletion(data) {
  return request({
    url: originApi + '/v4/U109',
    method: 'get',
    headers: {
      'access-key': data.replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+')
    }
  })
}
//基础认证
export function updateUserIdInfo(d, data) {
  return request({
    url: originApi + '/v4/U128',
    method: 'POST',
    headers: {
      'access-key': d.replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+').replace(' ', '+')
    },
    data
  })
}
// 转盘活动接口区域---------------------------------------------------------------------
export function queryUserStyle(data, accessKey) {
  return request({
    url: originApi + '/v4/U141',
    method: 'POST',
    data,
    headers: {
      'access-key': accessKey
    }
  })
}
export function getQuestList(data, accessKey) {
  return request({
    url: originApi + '/v4/PEM0009',
    method: 'POST',
    data,
    headers: {
      'access-key': accessKey
    }
  })
}
export function exchangePoints(data, accessKey) {
  return request({
    url: originApi + '/v4/PEM0008',
    method: 'POST',
    data,
    headers: {
      'access-key': accessKey
    }
  })
}
